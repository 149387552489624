<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.credit_usages') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button-->
          <!--                    <router-link to="/points-credits/credit-usages" class="btn btn-light font-weight-bolder">-->
          <!--                        <v-icon>mdi-chevron-left</v-icon>-->
          <!--                        {{$t('back')}}-->
          <!--                    </router-link>-->
          <!--end::Button-->
        </div>
      </div>

      <div class="card-body">
        <b-card-body class="p-0">
          <div class="form-group row">

            <div class="col-lg-6 mb-5">
              <label for="client_id">{{ $t('credit_usages.client') }}<span class="text-danger">*</span></label>
              <div class="input-group mb-3">
                <multiselect v-model="customer"
                             id="client_id"
                             :placeholder="$t('credit_usages.client')"
                             label="name"
                             track-by="id"
                             :options="customers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getCustomers($event)">
                </multiselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/customers/customers/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                </div>
              </div>
              <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('credit_usages.client') }}.</span>

              <span v-if="validation && validation.client_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.client_id[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('credit_usages.usage_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.usage_date" class="form-control" :class="validation && validation.usage_date ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.usage_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.usage_date[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label for="credit_type">{{ $t('credit_usages.credit_type') }}</label>
              <div class="input-group mb-3">
                <multiselect v-model="credit_type"
                             id="credit_type"
                             :placeholder="$t('credit_usages.credit_type')"
                             label="name"
                             track-by="id"
                             :options="credit_types"
                             :multiple="false"
                             :taggable="true"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <div class="input-group-prepend">
                  <a class="btn btn-primary" href="/points-credits/credit-types/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('credit_usages.usage_amount') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.usage_amount" class="form-control" :class="validation && validation.usage_amount ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.usage_amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.usage_amount[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('credit_usages.description') }}</label>
              <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
              <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
            </div>

          </div>
        </b-card-body>

        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-credit-usages",
  data() {
    return {
      mainRoute: 'pointscredits/credit-usages',
      mainRouteDependency: 'base/dependency',


      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        client_id: null,
        credit_type_id: '',
        usage_amount: '',
        usage_date: '',
        status: true,
        description: null,
      },
      customers: [],
      credit_types: [],
      credit_type: null,
      isEditing: false,
      validation: null,
      status_list: [],
      customer: null,

    };
  },
  watch: {
    customer: function (val) {
      if (val) {
        this.data.client_id = val.id;
      } else {
        this.data.client_id = null;
      }
    },
    credit_type: function (val) {
      if (val) {
        this.data.credit_type_id = val.id;
      } else {
        this.data.credit_type_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/points-credits/credit-usages');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/points-credits/credit-usages');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status_open").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getCreditTypes() {
      ApiService.get(this.mainRouteDependency + "/credit_types").then((response) => {
        this.credit_types = response.data.data;
      });
    },

    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.client_id = response.data.data.client_id;
        this.data.credit_type_id = response.data.data.credit_type_id;
        this.data.usage_amount = response.data.data.usage_amount;
        this.data.usage_date = response.data.data.usage_date;
        this.credit_type = response.data.data.credit_type;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
        this.data.allow_decimal = response.data.data.allow_decimal;
        this.customer = response.data.data.client;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.credit_usages"), route: '/points-credits/credit-usages'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    this.getStatus();
    this.getCustomers();
    this.getCreditTypes();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


